@font-face {
    font-family: 'SFPro';
    src: url('./assets/fonts/SFPro/SF-Pro-Display-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SFPro';
    src: url('./assets/fonts/SFPro/SF-Pro-Display-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SFPro';
    src: url('./assets/fonts/SFPro/SF-Pro-Display-Semibold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SFPro';
    src: url('./assets/fonts/SFPro/SF-Pro-Display-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}



/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'SFPro', sans-serif;
}

body {
    overflow: hidden;
}


/* General hover effect for all toolbar buttons */
#toolbar .ql-formats button {
    background: none; /* Remove default background */
    border: none;     /* Remove default border */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease, transform 0.2s ease;
    border-radius: 4px; /* Rounded edges for the hover effect */
}

/* Hover effect */
#toolbar .ql-formats button:hover {
    background-color: rgba(0, 0, 0, 0.1); /* Light grey background */
}

/* Optional: Active effect when a format is applied */
#toolbar .ql-formats button.ql-active {
    background-color: rgba(0, 0, 0, 0.2); /* Darker background for active state */
}

svg { 
    display: flex;
    justify-content: center;
    align-items: center;
    display: block; /* Ensures SVG respects its container */
    overflow: visible; /* Prevents clipping */
}


/* Scrollbar styles */

/* General scrollbar styles */
::-webkit-scrollbar {
    height: 20px; /* Scrollbar height for horizontal scrolling */
    width: 20px;  /* Scrollbar width for vertical scrolling */
}



/* Scrollbar track (background area) */
::-webkit-scrollbar-track {
    background-color: transparent; /* Light gray background */
    border-radius: 10px;      /* Rounded corners */
}

/* Scrollbar thumb (scrolling indicator) */
::-webkit-scrollbar-thumb {
    background-color: rgba(233, 234, 235, 1); /* Thumb color */
    border-radius: 10px;       /* Rounded corners */
    background-clip: padding-box;
    border: 6px solid transparent;
}

/* Scrollbar hover state */
::-webkit-scrollbar-thumb:hover {
    background-color: rgba(210, 214, 218, 1); /* Darker thumb color on hover */
}

.MuiTabs-scroller {
    scrollbar-width: thin!important; /* Thin scrollbar */
}

.MuiTabs-scroller::-webkit-scrollbar {
    height: 20px !important;
    width: 20px !important;
}
.MuiTabs-scroller::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: 10px !important;
}
.MuiTabs-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(233, 234, 235, 1) !important;
    border-radius: 10px !important;
    background-clip: padding-box !important;
    border: 6px solid transparent !important;
}
.MuiTabs-scroller::-webkit-scrollbar-thumb:hover {
    background-color: rgba(210, 214, 218, 1) !important;
}
/* Firefox scrollbar styles */
/* .custom-scrollbar {
    scrollbar-width: thin; /* Thin scrollbar 
    scrollbar-color: #c40c0c #f1f2f3; /* Thumb color and track color 
} */

/* --------------------------- */

/* image cropping area */
.reactEasyCrop_CropArea::after, .reactEasyCrop_CropArea::before {
    content: none !important;
}

.custom-input::placeholder {
    color: #ADB5BD;
    font-weight: 300;
}


/* Quill working css */
.ql-block{
    white-space: pre-wrap!important;
}

.vacancy-create-ai-generation .ql-editor::before {
    display: none !important;
}

.vacancy-create-ai-generation .ql-editor::after {
    content: "AI Generating...";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    color: white;
    width: 100%;
    height: 100%;
    font-size: 12px;
    border-radius: 4px;
    background: url("./assets/icons/AiGenerationIcon.gif") no-repeat center;
}
  
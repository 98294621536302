.custom-select {
    position: relative;
    width: 120px;
    cursor: pointer;
    margin-left: 32px;
  }
  
  .selected-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    font-size: 16px;
    font-weight: 500;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .arrow-icon {
    transition: transform 0.3s ease;
  }
  
  .arrow-icon.rotate {
    transform: rotate(180deg);
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
    overflow: hidden;
    z-index: 10;
  }
  
  .dropdown-item {
    padding: 10px 12px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.2s ease;
  }
  
  .dropdown-item:hover {
    background: #f1f3f5;
  }
  
  .dropdown-item.active {
    background: #e7effb;
    color: #1d4ed8;
    font-weight: 600;
  }
  
.week-view-container {
  display: flex;
  width: 100%;
}
.week-view {
  width: 100%;
  background: #fff;
  border-radius: 8px;
}

.week-header {
  display: grid;
  grid-template-columns: 60px repeat(7, 1fr);
  border-bottom: 1px solid #ddd;
  padding: 8px 0;
  background: #fff;
  align-items: center;
}

.week-day {
  display: flex;
  justify-content: center;
  gap: 8px;
}
.week-day-name {
  color: #6D7278;
}

.week-day.today {
  border-radius: 8px;
  font-weight: bold;
}

.week-time-label {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #555;
}

.week-body {
  display: flex;
  height: 1920px; /* 24 строки * 80px */
}

.time-column {
  width: 60px;
  display: flex;
  flex-direction: column;
  background: #f8f9fa;
  border-right: 1px solid #ddd;
}

.time-cell {
  height: 80px; /* Высота строки теперь 80px */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: bold;
  color: #555;
  padding-right: 10px;
  position: relative;
  padding-top: 5px;
}

.time-cell span{
  position: absolute;
  bottom: -8px;
}

.week-grid {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(24, 80px) !important; /* Жестко фиксируем 24 строки */
}



.day-column {
  display: flex;
  flex-direction: column;
}

.week-cell {
  min-height: 80px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #eee;
  background: #fff;
  cursor: pointer;
  position: relative;
}

.week-cell:hover {
  background: rgba(0, 123, 255, 0.1);
}


.slot-modal {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 8px;
  box-shadow: 0 5px 20px rgba(0,0,0,0.2);
  padding: 20px;
  z-index: 1000;
}

.slot-modal-content {
  position: relative;
  width: 300px;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
}

.calendar-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  background: #fff;
  border-radius: 8px;
}

.calendar-month {
  display: flex;
  align-items: center;
  gap: 8px;
}

.calendar-month .month {
  color: #599D15;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-transform: capitalize;
}

.calendar-month .year {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
}

.view-mode-select {
  padding: 6px 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
  outline: none;
  margin-left: 32px;
}



.nav-button {
  padding: 5px 10px;
  margin: 0 5px;
  border: 0.5px solid #F1F2F3;
  background: #f5f5f5;
  cursor: pointer;
  border-radius: 5px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: minmax(34px, 1fr);
  width: 100%;
  height: 80vh;
  border: 0.5px solid #F1F2F3;
  margin-top: 0;
}

.calendar-day-header {
  text-align: center;
  font-weight: 600;
  padding: 5px;
  background: #fff;
  border: 0.5px solid #F1F2F3;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

}

.calendar-day {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 9px 9px;
  border: 0.5px solid #ddd;
  position: relative;
  background: #fff;
  overflow: hidden;
  height: 100%;
  font-size: 12px;
  font-weight: 600;
}

.inactive {
  background: #F1F2F3;
  color: #9BA2A9;
}

.day-number {
  font-size: 12px;
  font-weight: 600;
}

.today {
  color: #007bff;
}

.event-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  margin-top: 9px;
  padding-right: 4px; 
}

.event-container::-webkit-scrollbar {
  width: 4px; 
}

.event-container::-webkit-scrollbar-track {
  background: transparent; 
}

.event-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.event {
  background: #e7effb;
  color: #1d4ed8;
  padding: 6px 12px;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 24px;
}



.event::before {
  content: "";
  width: 4px;
  height: 100%;
  background: var(--event-color);
  position: absolute;
  left: 0;
  top: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}


.calendar-controls {
  display: flex;
  align-items: center;
}

.create-dropdown {
  position: relative;
}

.create-button {
  display: flex;
  align-items: center;
  gap: 6px;
  background: #599D15;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  height: 46px;
}

.create-button .plus-icon {
  font-size: 18px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 140px;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.dropdown-item {
  padding: 10px 16px;
  font-size: 14px;
  color: #151813;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.dropdown-item:hover {
  background: #f5f5f5;
}
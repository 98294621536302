.slots-manager {
    min-width: 377px;
    background: #ffffff;
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    /* position: fixed; */
    left: 314px;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: top 0.3s ease-in-out; /* Плавное движение */

}

.slot-block {
    background: rgba(0, 128, 0, 0.2);
    border: 1px solid green;
    position: absolute;
    width: 90%;
    left: 5%;
    border-radius: 5px;
}


.slots-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slots-header h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
}

.close-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
    transition: color 0.2s;
}

.close-btn:hover {
    color: #ff4d4d;
}

.slots-content label {
    margin: 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #151813;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.gmt {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #151813;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/* .slots-content input,
.slots-content select {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
} */

.time-input {
    background-color: #F8F9F9;
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #ADB5BD;
}

.slots-times {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.slots-row {
    display: block;
    align-items: center;
    gap: 8px;
    margin-top: 10px;
}

.time-slot {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.slots-row span {
    width: 30px;
    font-weight: 400;
    text-align: center;
    color: #2E4052;
    font-size: 16px;
}

.slots-row input {
    width: 80px;
}

.save-btn {
    width: 100%;
    margin-top: 16px;
    background: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s;
}

.save-btn:hover {
    background: #45a049;
}
